export enum authenticationActions {
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  REGISTER_FAIL = "REGISTER_FAIL",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_FAIL = "LOGIN_FAIL",
  LOGOUT = "LOGOUT",
  RESET_SUCCESS = "RESET_SUCCESS",
  RESET_FAIL = "RESET_FAIL",
}
export enum userActions {
  LIST_USER_SUCCESS = "LIST_USER_SUCCESS",
  LIST_USER_FAILED = "LIST_USER_FAILED",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED = "DELETE_USER_FAILED",
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  CREATE_USER_FAILED = "CREATE_USER_FAILED",
  EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS",
  EDIT_USER_FAILED = "EDIT_USER_FAILED"
}

export enum snackBarActions {
  SET_SNACKBAR = "SET_SNACKBAR",
}

export enum restaurantActions {
  LIST_RESTAURANT_SUCCESS = "LIST_RESTAURANT_SUCCESS",
  LIST_RESTAURANT_FAILED = "LIST_RESTAURANT_FAILED",
  DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS",
  DELETE_RESTAURANT_FAILED = "DELETE_RESTAURANT_FAILED",
  CREATE_RESTAURANT_SUCCESS = "CREATE_RESTAURANT_SUCCESS",
  CREATE_RESTAURANT_FAILED = "CREATE_RESTAURANT_FAILED",
  EDIT_RESTAURANT_SUCCESS = "EDIT_RESTAURANT_SUCCESS",
  EDIT_RESTAURANT_FAILED = "EDIT_RESTAURANT_FAILED"
}

export enum restaurantCategoryActions {
  LIST_RESTAURANTCATEGORY_SUCCESS = "LIST_RESTAURANTCATEGORY_SUCCESS",
  LIST_RESTAURANTCATEGORY_FAILED = "LIST_RESTAURANTCATEGORY_FAILED",
  DELETE_RESTAURANTCATEGORY_SUCCESS = "DELETE_RESTAURANTCATEGORY_SUCCESS",
  DELETE_RESTAURANTCATEGORY_FAILED = "DELETE_RESTAURANTCATEGORY_FAILED",
  CREATE_RESTAURANTCATEGORY_SUCCESS = "CREATE_RESTAURANTCATEGORY_SUCCESS",
  CREATE_RESTAURANTCATEGORY_FAILED = "CREATE_RESTAURANTCATEGORY_FAILED",
  EDIT_RESTAURANTCATEGORY_SUCCESS = "EDIT_RESTAURANTCATEGORY_SUCCESS",
  EDIT_RESTAURANTCATEGORY_FAILED = "EDIT_RESTAURANTCATEGORY_FAILED"
}

export enum mealCategoryActions {
  LIST_MEALCATEGORY_SUCCESS = "LIST_MEALCATEGORY_SUCCESS",
  LIST_MEALCATEGORY_FAILED = "LIST_MEALCATEGORY_FAILED",
  DELETE_MEALCATEGORY_SUCCESS = "DELETE_MEALCATEGORY_SUCCESS",
  DELETE_MEALCATEGORY_FAILED = "DELETE_MEALCATEGORY_FAILED",
  CREATE_MEALCATEGORY_SUCCESS = "CREATE_MEALCATEGORY_SUCCESS",
  CREATE_MEALCATEGORY_FAILED = "CREATE_MEALCATEGORY_FAILED",
  EDIT_MEALCATEGORY_SUCCESS = "EDIT_MEALCATEGORY_SUCCESS",
  EDIT_MEALCATEGORY_FAILED = "EDIT_MEALCATEGORY_FAILED"
}
export enum elementActions {
  LIST_ELEMENT_SUCCESS = "LIST_ELEMENT_SUCCESS",
  LIST_ELEMENT_FAILED = "LIST_ELEMENT_FAILED",
  DELETE_ELEMENT_SUCCESS = "DELETE_ELEMENT_SUCCESS",
  DELETE_ELEMENT_FAILED = "DELETE_ELEMENT_FAILED",
  CREATE_ELEMENT_SUCCESS = "CREATE_ELEMENT_SUCCESS",
  CREATE_ELEMENT_FAILED = "CREATE_ELEMENT_FAILED",
  EDIT_ELEMENT_SUCCESS = "EDIT_ELEMENT_SUCCESS",
  EDIT_ELEMENT_FAILED = "EDIT_ELEMENT_FAILED"
}
export enum ingredientsActions {
  LIST_INGREDIENTS_SUCCESS = "LIST_INGREDIENTS_SUCCESS",
  LIST_INGREDIENTS_FAILED = "LIST_INGREDIENTS_FAILED",
  DELETE_INGREDIENTS_SUCCESS = "DELETE_INGREDIENTS_SUCCESS",
  DELETE_INGREDIENTS_FAILED = "DELETE_INGREDIENTS_FAILED",
  CREATE_INGREDIENTS_SUCCESS = "CREATE_INGREDIENTS_SUCCESS",
  CREATE_INGREDIENTS_FAILED = "CREATE_INGREDIENTS_FAILED",
  EDIT_INGREDIENTS_SUCCESS = "EDIT_INGREDIENTS_SUCCESS",
  EDIT_INGREDIENTS_FAILED = "EDIT_INGREDIENTS_FAILED"
}
export enum extrasActions {
  LIST_EXTRAS_SUCCESS = "LIST_EXTRAS_SUCCESS",
  LIST_EXTRAS_FAILED = "LIST_EXTRAS_FAILED",
  DELETE_EXTRAS_SUCCESS = "DELETE_EXTRAS_SUCCESS",
  DELETE_EXTRAS_FAILED = "DELETE_EXTRAS_FAILED",
  CREATE_EXTRAS_SUCCESS = "CREATE_EXTRAS_SUCCESS",
  CREATE_EXTRAS_FAILED = "CREATE_EXTRAS_FAILED",
  EDIT_EXTRAS_SUCCESS = "EDIT_EXTRAS_SUCCESS",
  EDIT_EXTRAS_FAILED = "EDIT_EXTRAS_FAILED"
}
export const TOKEN = "Token"